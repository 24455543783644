

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class TimeLineEndpoint extends EndpointFactory {

  private readonly _timeLineUrl: string = "/TimeLine";
 

  get timeLineUrl() { return this.configurations.baseUrl + this._timeLineUrl; }
  



  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }



  getTimeLineEndpoint<T>(userId?: string,page?:number,pageSize?:number): Observable<T> {
    let endpointUrl = userId ? `${this.timeLineUrl}/${userId}/${page}/${pageSize}` : this.timeLineUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTimeLineEndpoint(userId));
      }));
  }


  
  getNewTimeLineEndpoint<T>(timeLineObject: any): Observable<T> {

    return this.http.post<T>(this.timeLineUrl, JSON.stringify(timeLineObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewTimeLineEndpoint(timeLineObject));
      }));
  }

  

  

  getDeleteTimeLineEndpoint<T>(timeLineId: number): Observable<T> {
    let endpointUrl = `${this.timeLineUrl}/${timeLineId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteTimeLineEndpoint(timeLineId));
      }));
  }
  

  
}
