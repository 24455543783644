

import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';




import { TimeLineEndpoint } from './timeline-endpoint.service';
import { AuthService } from './auth.service';
import { User } from 'app/models/user.model';
import { TimeLine } from 'app/models/timeline.model';
import { Role } from 'app/models/role.model';
import { Permission, PermissionNames, PermissionValues } from '../models/permission.model';
import { UserEdit } from 'app/models/user-edit.model';






@Injectable()
export class TimeLineService {

  


  constructor(private router: Router, private http: HttpClient, private authService: AuthService,
    private timeLineEndpoint: TimeLineEndpoint) {

  }


  getTimeLine(userId?: string,page?:number,pageSize?:number) {
    return this.timeLineEndpoint.getTimeLineEndpoint<User>(userId,page,pageSize);
  }


  newTimeLine(timeLine: TimeLine) {
    return this.timeLineEndpoint.getNewTimeLineEndpoint<TimeLine>(timeLine);
  }


  /*createUser (user: UserEdit): Observable<UserEdit> {
    return this.http.post<UserEdit>(this.accountEndpoint.signUpUrl, user);
     
  }
*/
 
  
  



 



}
